<template>
  <main class="home">
    <div class="invoice-container">
      <form @submit.prevent class="form">
        <template v-if="reset">
          <h2>Reset Password</h2>
          <div class="input-item">
            <label class="form__label" for="client-email"> Email </label>
            <input
              class="form__input"
              v-model.trim="form.email"
              type="email"
            />
          </div>

          <div class="input-item">
            <button class="submit" type="submit" @click="resetCode()">
              <clip-loader color="white" size="1em" v-if="isLoading" />
              <template v-else> Request Code </template>
            </button>
          </div>
        </template>
        <template v-else-if="reset2">
          <h2>Reset Password</h2>
          <div style="padding: 10px; background: green; border-radius: 5px;">We have emailed your password reset code!</div>
          <div class="input-item">
            <label class="form__label" for="client-email"> Reset Code</label>
            <input
              class="form__input"
              v-model.trim="form.code"
              type="text"
            />
          </div>
          <div class="input-item">
            <label class="form__label" for="client-email"> Password Baru </label>
            <input
              class="form__input"
              v-model.trim="form.password"
              type="password"
            />
          </div>

          <div class="input-item">
            <button class="submit" type="submit" @click="resetPassword()">
              <clip-loader color="white" size="1em" v-if="isLoading" />
              <template v-else> Reset Password </template>
            </button>
          </div>
        </template>
        <template v-else>
          <h2>Login</h2>
          <div v-if="success" style="padding: 10px; background: green; border-radius: 5px;">{{ success }}</div>
          <div class="input-item">
            <label class="form__label" for="client-email"> Email </label>
            <input
              class="form__input"
              v-model.trim="form.email"
              type="email"
              name="email"
              id="client-email"
            />
          </div>

          <div class="input-item">
            <label class="form__label" for="client-password"> Password </label>
            <input
              class="form__input"
              v-model.trim="form.password"
              type="password"
              name="password"
              id="client-password"
            />
          </div>

          <div class="input-item">
            <button class="submit" type="submit" value="Login" @click="login()">
              <clip-loader color="white" size="1em" v-if="isLoading" />
              <template v-else> Login </template>
            </button>
          </div>
        </template>
        <a href="#" role="button" @click="reset=!reset">{{reset ? 'Login' : 'Forgot Password'}}</a>
      </form>
    </div>
  </main>
</template>

<script>
// import Axios from "axios";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: "Login",
  components: {
    ClipLoader,
  },
  data() {
    return {
      form: this.getClearForm(),
      isLoading: false,
      reset: false,
      reset2 : false,
      success: null
    };
  },
  methods: {
    getClearForm() {
      return {
        email: null,
        password: null,
      };
    },
    login() {
      this.isLoading = true;
      this.$store
        .dispatch("login", this.form)
        .then(() => {
          this.$router.push(this.$route.query.redirect || { name: "Home" });
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => {
          this.submitLoading = false;
          this.isLoading = false;
        });
    },
    resetCode() {
      this.isLoading = true;
      this.$store
        .dispatch("resetCode", this.form)
        .then((res) => {
          if(res.status === 'ok') {
            this.reset = false;
            this.reset2 = true
          }
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => {
          this.submitLoading = false;
          this.isLoading = false;
        });
    },
    resetPassword() {
      this.isLoading = true;
      this.$store
        .dispatch("resetPassword", this.form)
        .then((res) => {
          if(res.status === 'ok') {
            this.reset = false;
            this.reset2 = false;
            this.success = res.message
          }
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => {
          this.submitLoading = false;
          this.isLoading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.submit {
  background-color: #7b5cfa;
}
.submit:hover {
  background-color: #9175ff;
}
.invoice-container {
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  min-width: 30vw;
}
form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  overflow-x: hidden;
  padding: 30px;
  border-radius: 20px;
  background: #1e2238;
  color: white;
}
label {
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 12px;
}
input,
select {
  padding: 16px 13px 16px 20px;
  border-radius: 4px;
  border: 1px solid hsl(233, 30%, 21%);
  background-color: hsl(233, 31%, 17%);
  font-weight: 700;
  color: white;
}
select {
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-item {
  display: flex;
  flex-direction: column;
}
.input-group {
  display: flex;
  justify-content: space-between;
}
.input-group > .input-item {
  width: 30%;
}
button {
  border: none;
  border-radius: 24px;
  cursor: pointer;
  padding: 17px 24px;
  color: white;
  font-weight: 700;
}
</style>
